/* eslint-disable eqeqeq */
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useEffect, useState, Suspense } from "react";
import Loader from "./components/Loader";
import LoadingOverlay from "react-loading-overlay";
import {
  initialModalState,
  initialState,
  modalValues,
  routes,
  type,
  utility_benefit,
} from "./utils/constant";
import {
  circle_id,
  hash_msdin,
  msisdn_data,
  ota_version,
  parent_msisdn,
  user_name,
  selected_profile,
  last_recharge_pack,
} from "./utils/constant";
import { getMsisdnData } from "./utils/commonFunction";
import { AuthContext } from "./services/AuthContext";

const SpinWheel = React.lazy(() => import("./components/SpinWheel"));
const RewardHistory = React.lazy(() => import("./components/RewardHistory"));
const PDFReader = React.lazy(() => import("./components/PDFReader"));
function App(props) {
  const { terms_conditions, reward_history } = routes;
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({ ...initialState });
  const [modalState, setModalState] = useState({ ...initialModalState });
  const [token, setToken] = useState(null);
  let { msisdn, parentMsisdn, circleId, name } = getMsisdnData(
    document.location.href.split("=")?.[1]
  );
  const { OfferApplicableModal, OfferApplicableUtilityModal } = modalValues;
  let newState;

  useEffect(() => {
    return () => {
      localStorage.removeItem("canSpin");
    };
  }, []);

  useEffect(() => {
    if (window.location.href.includes("localhost")) {
      localStorage.setItem(msisdn_data, "8883365510");
      localStorage.setItem(parent_msisdn, "8883365510");
      localStorage.setItem(circle_id, "0014");
      localStorage.setItem(user_name, "Kapil");
      localStorage.setItem(ota_version, "10.10.0");
      localStorage.setItem(selected_profile, "8883365510");
      localStorage.setItem(last_recharge_pack, "239");
      localStorage.setItem(
        hash_msdin,
        "5c858d785b9122f642e193f1989f93ff81820c5bfca4b349b78e2293f50db804"
      );
      localStorage.setItem(type, "RECHARGE");
      localStorage.setItem(utility_benefit, "");
    }
  }, []);

  const handleState = (newState) => {
    setState((preState) => ({
      ...preState,
      ...newState,
    }));
  };

  const handleModalState = (isOpen, modalName) => {
    setModalState((preState) => ({
      ...modalValues[modalName],
      isOpen,
      modalName,
    }));
  };

  const toggleModal = () => {
    if (
      modalState.modalName == OfferApplicableModal.modalName ||
      modalState.modalName == OfferApplicableUtilityModal.modalName
    ) {
      newState = {
        disableSpin: true,
      };
      handleState(newState);
    }
    setModalState((prev) => ({
      ...initialModalState,
      isOpen: false,
    }));
  };

  return (
    <>
      <AuthContext.Provider value={{ token, setToken }}>
        <Suspense fallback={<Loader />}>
          <LoadingOverlay
            active={loading}
            {...props}
            spinner={<Loader />}
            style={{ height: "100%" }}
          >
            <Router>
              <Routes>
                <Route
                  path="/"
                  element={
                    <SpinWheel
                      modalState={modalState}
                      handleModalState={handleModalState}
                      toggleModal={toggleModal}
                      handleState={handleState}
                      state={state}
                      tagline={""}
                      msisdn={msisdn}
                      parentMsisdn={parentMsisdn}
                      circleId={circleId}
                      name={name}
                      setLoading={setLoading}
                      loading={loading}
                    />
                  }
                />
                <Route
                  path={reward_history}
                  element={
                    <RewardHistory
                      modalState={modalState}
                      handleModalState={handleModalState}
                      toggleModal={toggleModal}
                      msisdn={msisdn}
                      setLoading={setLoading}
                      loading={loading}
                    />
                  }
                />
                <Route
                  path={terms_conditions}
                  element={<PDFReader setLoading={setLoading} />}
                />
              </Routes>
            </Router>
          </LoadingOverlay>
        </Suspense>
      </AuthContext.Provider>
    </>
  );
}

export default App;
